import request from "@/utils/requests";
import md5 from 'js-md5';

export const rechargeWallet = () => {
  return request.post('/trade/recharge_wallet', {
    loadding:false
  })
}

export const userRecharge = (num,from_address,to_address_id) => {
  return request.post('/trade/recharge', {
    num:num,
    to_address_id:to_address_id,
    from_address:from_address,
    loadding:true,
  })
}

export const withdrawalInfo = () => {
  return request.post('/trade/withdraw', {
    loadding:false
  })
}

export const userWithdrawal = (num,address,type,paypassword) => {
  let cur_time = Date.now();
  let pwd_md5 = md5(md5(paypassword) + cur_time);
  return request.post('/trade/do_withdraw', {
    type:type,
    num:num,
    address:address,
    paypassword:pwd_md5,
    time:cur_time,
    loadding:false
  })
}

export const userWalletType = () => {
  return request.post('/trade/wallet_type', {
    loadding:false
  })
}

export const userWallet = (market) => {
  return request.post('/trade/user_wallet', {
    market:market,
    loadding:false
  })
}

export const walletTransfer = (from_market,to_market,currency,amount) => {
  return request.post('/trade/do_transfer', {
    from_market:from_market,
    to_market:to_market,
    currency:currency,
    amount:amount,
    loadding:true,
  })
}

export const optionsPackages = () => {
  return request.post('/trade/options_packages', {})
}

export const optionsOrderList = (status) => {
  return request.post('/trade/options_list', {
    status:status,
    loadding:false
  })
}

export const optionsOrderInfo = (order_id) => {
  return request.post('/trade/options_list', {
    order_id:order_id,
    loadding:true
  })
}

export const orderOptions = (package_id,target,direct,amount) => {
  return request.post('/trade/order_options', {
    package_id:package_id,
    target:target,
    direct:direct,
    amount:amount,
    loadding:true,
  })
}

