import request from "@/utils/requests"
import md5 from 'js-md5';

export const doRegister = (tel_in, username_in, login_pwd_in,with_pwd_in,invite_code) => {
    let pwd1_md5 = md5(login_pwd_in);
    let pwd2_md5 = md5(with_pwd_in);
    return request.post('/client/register_action', {
        tel:tel_in,
        user_name:username_in,
        pwd:pwd1_md5,
        pwd2:pwd2_md5,
        invite_code:invite_code,
        loadding:true,
    })
}

export const doLogin = (tel_in,pwd_in) =>{
  let cur_time = Date.now();
  let pwd_md5 = md5(md5(pwd_in) + cur_time);
  return request.post('/client/login_action',{
      tel:tel_in,
      pwd:pwd_md5,
      time:cur_time,
      loadding:true,
  })
}

export const doLogout = () =>{

  return request.post('/client/signout',{
    loadding:true,
  })
}

export const customerService = () =>{
  return request.post('/clientcenter/customer_service',{
    loadding:false
  })
}
