import request from "@/utils/requests";

export const getConfigPage = (pos_in) => {
  return request.post('/home/config_page', {
    pos:pos_in,
    loadding:true,
  })
}


export const getRegisterNote = () => {
  return request.post('/home/reg_note', {
    pos:6,
    loadding:true,
  })
}
