import request from "@/utils/requests";

export const getHomeData = () => {
    return request.post('/home/home_index', {
      loadding:false
    })
}

export const getHomeQuote = () => {
    return request.post('/home/home_quote', {
      loadding:false
    })
}
