import request from "@/utils/requests";

export const klineData = (target,base,type,to) => {
  return request.post('/ticker/klinev2', {
    target:target,
    base:base,
    type:type,
    to:to,
    loadding:false
  })
}

export const tickerQuote = (target,base) => {
  return request.post('/ticker/ticker_quote', {
    target:target,
    base:base,
    loadding:false
  })
}

export const tradeInfo = (target,base) => {
  return request.post('/ticker/trade_info', {
    target:target,
    base:base,
    loadding:false
  })
}

export const depthData = (target,base) => {
  return request.post('/ticker/depth_data', {
    target:target,
    base:base,
    loadding:false
  })
}
