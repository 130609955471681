import request from "@/utils/requests"
import md5 from 'js-md5';



// 获取个人信息
export const getUserInfo = () => {
  return request.post('/clientcenter/user_info',{
    loadding:true
  });
}

export const editLoginPasswd = (old_pass_in,new_pass_in1,new_pass_in2) => {
  let cur_time = Date.now();
  return request.post('/home/edit_login_pwd', {
    old_pwd: md5(md5(old_pass_in) + cur_time),
    new_pwd: md5(new_pass_in1),
    password_confirm: md5(new_pass_in2),
    time: cur_time,
    loadding:true,
  });
}

export const editWithdrawPasswd = (old_pass_in,new_pass_in1,new_pass_in2) => {
  let cur_time = Date.now();
  return request.post('/home/edit_withdrawal_pwd', {
    old_pwd: md5(md5(old_pass_in) + cur_time),
    new_pwd: md5(new_pass_in1),
    password_confirm: md5(new_pass_in2),
    time: cur_time,
    loadding:true,
  })
}

export const msgList = () => {
  return request.post('/clientcenter/message_list', {
    loadding:true,
  })
}

export const msgContent = (id_in) => {
  return request.post('/clientcenter/message_content', {
    id:id_in,
    loadding:true,
  })
}

export const msgRead = (id_in) => {
  return request.post('/clientcenter/reads', {
    id:id_in,
    loadding:false
  })
}

export const checkPwd = (pwd_in) => {
  let cur_time = Date.now();
  return request.post('/clientcenter/check_bank_pwd', {
    time:cur_time,
    pwd:md5(md5(pwd_in)+cur_time),
    loadding:true,
  })
}

export const checkMsgList = () => {
  return request.post('/home/check_unread_msg_num', {
    loadding:false
  })
}
